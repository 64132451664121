<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 _col-md-10 _offset-md-1 col-lg-auto mx-lg-auto outer-card">
        <div class="card border-primary">

          <div class="card-header text-white bg-primary">
            <div class="row">
              <div class="col-7 col-sm-8">
                <span class="my_h5">社会人基礎力テスト <span class="d-none d-sm-inline">{{ examination? `${examination.exam_name} `: '' }}{{ currentExaminationPart? `${currentExaminationPart.exam_part_name}`: '' }}</span></span>
              </div>
              <div class="col-5 col-sm-4 text-right my_16px">
                <div v-if="currentExaminationPart">画面{{ currentPage }} / 全{{ currentExaminationPart.page_count }}画面</div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-sm-none my_16px">{{ examination? `${examination.exam_name} `: '' }}{{ currentExaminationPart? `${currentExaminationPart.exam_part_name}`: '' }}</div>
              <div class="col-6 col-sm-12 text-right my_16px">残り時間： {{ remainingTimeStr }}</div>
            </div>
          </div><!-- card-header -->

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <template v-if="currentExaminationPart">

              <div class="row">
                <div class="col-12 text-right">
                  <button v-for="p in currentExaminationPart.page_count" :key="`page_${p}`" class="btn btn-link my_16px" :class="{ 'font-weight-bold': p==currentPage }" @click.prevent="onMovePage(p, true/*サーバーへ一時保存*/)">
                    <template v-if="p==1">最初</template>
                    <template v-else-if="p==currentExaminationPart.page_count">最後</template>
                    <template v-else>{{ p }}～</template>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-2" id="examination_parts_wrapper">
                  <div v-if="false">検査のメインコンポーネント（{{ examPartMainComponent }}）</div>
                  <component :is="examPartMainComponent" :examinationPart="currentExaminationPart" :currentPage="currentPage" :canEdit="false" :showDescription="false" v-model="currentExaminationPart.all_page_params_set" @input="onInput"></component>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-right">
                  <button v-if="currentPage>1" class="btn btn-primary mx-1 my-1" @click.prevent="onMovePage(currentPage-1, true/*サーバーへ一時保存*/)">前の画面へ</button>
                  <button v-if="currentPage<currentExaminationPart.page_count" class="btn btn-primary mx-1 my-1" @click.prevent="onMovePage(currentPage+1, true/*サーバーへ一時保存*/)">次の画面へ</button>
                  <button v-if="!isLastExamPart&&isLastPage" class="btn btn-warning mx-1 my-1" @click.prevent="onMoveToNextExamPart()">次の検査へ</button>
                  <button v-if="isLastExamPart&&isLastPage" class="btn btn-warning mx-1 my-1" @click.prevent="onFinishAll()">すべてを送信して終了する</button>
                </div>
              </div>

            </template><!-- row -->

            <div class="row mt-4">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
import common_t1 from '../../common/components/examination_parts/common_t1';
import common_t2 from '../../common/components/examination_parts/common_t2';
import dp2_t3_1 from '../../common/components/examination_parts/dp2_t3_1';
import dp2_t3_2 from '../../common/components/examination_parts/dp2_t3_2';
import dp3_t3_1 from '../../common/components/examination_parts/dp3_t3_1';
import dp3_t3_2 from '../../common/components/examination_parts/dp3_t3_2';
const LogHeader = 'Exam.vue';

export default {
  //PageTitle: 'Webテスト',
  mixins: [commonMixin],
  components: {
    common_t1,
    common_t2,
    dp2_t3_1, dp2_t3_2,
    dp3_t3_1, dp3_t3_2,
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      schoolExamination: null,
      examination: null,
      examinationParts: null,
      student: null,
      currentExamPartIdx: null,
      currentPage: null,
      remainingSec: null,
      timerId: null,
      cutOffTime: null,
      lastReportedData: null,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true/*, 'データを取得しています...'*/);
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.examinationParts = response.data.examination_parts;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        //全ての検査が終了している場合はhomeにリダイレクト
        if (this.student.finished_at) {
          console.log('redirecting exam to home');
          this.$router.push({ name: 'home' });
        }

        //再開判定
        //意外と判定が難しいのでサーバー側 getCurrentExaminationPartAttribute() や getIsCurrentExaminationPartStartedAttribute() のコメントも参照
        let isRestart = false;
        this.currentExamPartIdx = 0;
        if (this.student.current_exam_part != null) {
          for (let i=0; i<this.examinationParts.length; i++) {
            if (this.examinationParts[i].id == this.student.current_exam_part.id) {
              this.currentExamPartIdx = i;
              isRestart = this.student.is_current_exam_part_started;
              break;
            }
          }
        }

        if (!isRestart) {//検査開始？

          //サーバーへ検査の開始を通知
          this.putStudentExamPartToStart()
          .then((response) => {
            console.log(`[${LogHeader}] created() putStudentExamPartToStart()-then START`, response);
            let schoolExamStudentExamPart = response.data.sc_ex_student_exam_part;
            window.token = sessionStorage.token = response.data.access_token;//トークンリフレッシュ

            this.onMovePage(schoolExamStudentExamPart.current_page/*=1*/, false/*サーバーへの一時保存不要*/);//これで初めて画面が表示される

            this.startCountDownTimer(schoolExamStudentExamPart.remaining_sec);//カウントダウン開始

            console.log(`[${LogHeader}] created() putStudentExamPartToStart()-then END`);
          }).catch((error) => {
            console.error(`[${LogHeader}] created() putStudentExamPartToStart()-catch START`, error);
            this.setResult(error.response);
            console.error(`[${LogHeader}] created() putStudentExamPartToStart()-catch END`, error);
          });//↑開始通知

        } else {//検査再開？

          //サーバーへ検査の再開を通知
          this.putStudentExamPartToRestart()
          .then((response) => {
            console.log(`[${LogHeader}] created() putStudentExamPartToRestart-then START`, response);
            let schoolExamStudentExamPart = response.data.sc_ex_student_exam_part;
            window.token = sessionStorage.token = response.data.access_token;//トークンリフレッシュ

            this.reflectExamPartAnswerSet(schoolExamStudentExamPart.answer_set, this.currentExaminationPart);//現在の検査の答案一式を復元

            this.onMovePage(schoolExamStudentExamPart.current_page/*ページ位置の復元*/, false/*サーバーへの一時保存不要*/);//これで初めて画面が表示される

            this.startCountDownTimer(schoolExamStudentExamPart.remaining_sec);//カウントダウン開始

            console.log(`[${LogHeader}] created() putStudentExamPartToRestart-then END`);
          }).catch((error) => {
            console.error(`[${LogHeader}] created() putStudentExamPartToRestart-catch START`, error);
            this.setResult(error.response);
            console.error(`[${LogHeader}] created() putStudentExamPartToRestart-catch END`, error);
          });//↑再開通知

        }//if-else

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });//↑getSpecial(url)

  },//created()
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  beforeRouteLeave(to, from, next) {
    console.log(`[${LogHeader}] beforeRouteLeave() CALLED`);
    this.resetCountDownTimer();
    next();
  },
  computed: {
    currentExaminationPart() { return this.examinationParts&&this.currentExamPartIdx>=0&&this.currentExamPartIdx<this.examinationParts.length? this.examinationParts[this.currentExamPartIdx]: null; },
    examPartMainComponent() { return this.currentExaminationPart? this.currentExaminationPart.main_component_name: null; },
    isLastPage() { return this.currentExaminationPart? (this.currentPage == this.currentExaminationPart.page_count ): false; },
    isLastExamPart() { return this.examinationParts? (this.currentExamPartIdx == this.examinationParts.length - 1 ): false; },
    remainingTimeStr() {
      if (this.remainingSec == null) {
        return '';
      } else {
        if (this.remainingSec >= 60) {
          return `${parseInt(this.remainingSec / 60)}分${this.remainingSec % 60}秒`;
        } else if (this.remainingSec >= 0) {
          return `${this.remainingSec % 60}秒`;
        } else {
          //return `${-this.remainingSec}秒超過`;//デバッグ用（本番コメントアウト）
          return '';
        }
      }
    },
  },
  methods: {
    startCountDownTimer(sec) {
      console.log(`[${LogHeader}] startCountDownTimer(${sec}) CALLED`);
      this.resetCountDownTimer();
      this.cutOffTime = (new Date()).getTime() + sec * 1000;
      this.timerId = setInterval(() => {
        this.remainingSec = Math.ceil((this.cutOffTime - (new Date()).getTime()) / 1000);
      }, 1000);
    },
    stopCountDownTimer() {
      console.log(`[${LogHeader}] stopCountDownTimer() CALLED`);
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = null;
        //this.remainingSec = null;//停止後も remainingSec を参照するのでここではクリアしない ※ついでに cutOffTime のクリアも resetCountDownTimer() に任せる
        console.log(`[${LogHeader}] stopCountDownTimer() stopped timer`);
      }
    },
    resetCountDownTimer() {
      console.log(`[${LogHeader}] resetCountDownTimer() CALLED`);
      this.stopCountDownTimer();
      this.remainingSec = null;
      this.cutOffTime = null;
    },
    reflectExamPartAnswerSet(answerSet, examinationPart) {
      //（サーバーに保存されていた）答案一式をall_page_params_setに埋め込む ※admin/views/master/examination_parts/Edit.vueのafterCreatedGetThen()と似たロジック
      //このロジックはサーバー側で持つことが望ましいが、extractExamPartAnswerSet()との対比のためにクライアント側に置いている
      console.log(`[${LogHeader}] reflectExamPartAnswerSet() START`);
      //examinationPart.all_page_params_set内の各問題のanswerに復元
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        let pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            let questionParams = pageParams[questionKey];
            questionParams.answer = answerSet[questionKey] ?? [];
            //console.log(`[${pageKey}][${questionKey}]=[${questionParams.answer}]`);//デバッグ用（本番コメントアウト）
          }
        }
      }
      console.log(`[${LogHeader}] reflectExamPartAnswerSet() END`, examinationPart.all_page_params_set);
    },
    extractExamPartAnswerSet(examinationPart) {
      //現在のall_page_params_setから回答・答案を抽出して答案一式を作成する ※admin/views/master/examination_parts/Edit.vueのmakeSaveParams()と似たロジック
      //このロジックをサーバー側で持つことも検討したが、一時保存のサーバー負荷を考慮するとクライアントで処理する方が望ましい
      console.log(`[${LogHeader}] extractExamPartAnswerSet() START`);
      let answerSet = {};
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        let pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            let questionParams = pageParams[questionKey];
            let answer = questionParams.answer ?? [];
            //answer.sort();//入力順に格納されているのでソート → common_questionのinternalAnswerでソートするようにしたので不要
            answerSet[questionKey] = answer;
            //console.log(`[${pageKey}][${questionKey}]=[${answer}]`);//デバッグ用（本番コメントアウト）
          }
        }
      }
      console.log(`[${LogHeader}] extractExamPartAnswerSet() END`, answerSet);
      return answerSet;
    },
    putStudentExamPartToStart() {//currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToStart() CALLED`);
      const url = `sc_ex_student_exam_part/start`;
      return this.resourceApi.putSpecial(url, {
        id_examination_part: this.currentExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toLocaleString(),//or toISOString()
      });
    },
    putStudentExamPartToRestart() {//currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToRestart() CALLED`);
      const url = `sc_ex_student_exam_part/restart`;
      return this.resourceApi.putSpecial(url, {
        id_examination_part: this.currentExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toLocaleString(),//or toISOString()
      });
    },
    processPutStudentExamPartWithTmpData(skipIfSame) {//他と違ってthen,catchまでひとまとめにしてある
      console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData(${skipIfSame}) CALLED`);

      const answerSet = this.extractExamPartAnswerSet(this.currentExaminationPart);

      //（サーバー負荷を考慮して）前回と同じデータ（かつ前回送信からclient_report_interval_sec*10秒未満）なら送信しない
      if (skipIfSame) {
        const reportingData = {
          current_page: this.currentPage,
          answer_set_str: JSON.stringify(answerSet),
          last_reported_at_msec: Date.now(),
        };
        if (this.lastReportedData &&
            reportingData.current_page == this.lastReportedData.current_page &&
            reportingData.answer_set_str == this.lastReportedData.answer_set_str &&
            reportingData.last_reported_at_msec - this.lastReportedData.last_reported_at_msec < this.currentExaminationPart.client_report_interval_sec * 1000 * 10) {
          console.log(`SKIP reporting page(now/prev)[${reportingData.current_page}][${this.lastReportedData.current_page}] sec(now/prev)[${reportingData.last_reported_at_msec/1000}][${this.lastReportedData.last_reported_at_msec/1000}]`);
          return;
        }
        console.log(`NOT SKIP reporting sec(now/prev)[${reportingData.last_reported_at_msec/1000}][${this.lastReportedData?this.lastReportedData.last_reported_at_msec/1000:''}]`);
        this.lastReportedData = reportingData;
      }

      const url = `sc_ex_student_exam_part/tmp`;
      this.resourceApi.putSpecial(url, {
        id_examination_part: this.currentExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toLocaleString(),//or toISOString()
        remaining_sec: this.remainingSec,
        current_page: this.currentPage,
        answer_set: answerSet,
      })
      .then((response) => {
        console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-then START`, response);
        console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-catch END`, error);
      });
    },
    putStudentExamPartToFinish() {//currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToFinish() CALLED`);
      const url = `sc_ex_student_exam_part/finish`;
      return this.resourceApi.putSpecial(url, {
        id_examination_part: this.currentExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toLocaleString(),//or toISOString()
        remaining_sec: this.remainingSec,
        current_page: this.currentPage,
        answer_set: this.extractExamPartAnswerSet(this.currentExaminationPart),
      });
    },
    onInput(e) {
      //↓デバッグ用（本番コメントアウト）
      //console.log(`[${LogHeader}] onInput() CALLED`, e);
      //↑デバッグ用（本番コメントアウト）
      //↓デバッグ用（本番コメントアウト）
      //this.extractExamPartAnswerSet(this.currentExaminationPart);//extractExamPartAnswerSet()内のログもコメント解除しないといけない
      //↑デバッグ用（本番コメントアウト）
    },
    onMovePage(newPage, needsPut) {
      this.resetError();
      this.resetStatus();

      //ページ遷移がもたつくのでやめた方がよい
      // //サーバーへ現在の答案一式やページ番号を通知
      // if (needsPut) {
      //   this.processPutStudentExamPartWithTmpData(true/*currentPage変更前かつ答案に変更がなければスキップ*/);
      // }

      this.currentPage = newPage;
      window.scrollTo(0, 0);
    },
    onMoveToNextExamPart() {
      this.stopCountDownTimer();//カウントダウン停止
      this.resetError();
      this.resetStatus();
      this.setLoadingStatus(true);

      //サーバーへ現在の検査の終了を通知
      this.putStudentExamPartToFinish()
      .then((response) => {
        console.log(`[${LogHeader}] onMoveToNextExamPart() putStudentExamPartToFinish()-then START`, response);
        this.setLoadingStatus(false);
        this.resetCountDownTimer();

        //XXX 検査間にモーダル等を挟む必要があるか要確認 ※ ※b1の挙動未確認

        this.currentPage = null;//いったん画面を消す
        this.currentExamPartIdx++;

        //サーバーへ次の検査の開始を通知
        this.putStudentExamPartToStart()
        .then((response) => {
          console.log(`[${LogHeader}] onMoveToNextExamPart() putStudentExamPartToStart()-then START`, response);
          let schoolExamStudentExamPart = response.data.sc_ex_student_exam_part;
          window.token = sessionStorage.token = response.data.access_token;//トークンリフレッシュ

          this.onMovePage(schoolExamStudentExamPart.current_page/*1*/, false/*サーバーへの一時保存不要*/);//これで画面が再表示される

          this.startCountDownTimer(schoolExamStudentExamPart.remaining_sec);//カウントダウン開始

          console.log(`[${LogHeader}] onMoveToNextExamPart() putStudentExamPartToStart()-then END`);
        }).catch((error) => {
          console.error(`[${LogHeader}] onMoveToNextExamPart() putStudentExamPartToStart()-catch START`, error);
          this.setResult(error.response);
          console.error(`[${LogHeader}] onMoveToNextExamPart() putStudentExamPartToStart()-catch END`, error);
        });//↑開始通知

        console.log(`[${LogHeader}] onMoveToNextExamPart() putStudentExamPartToFinish()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] onMoveToNextExamPart() putStudentExamPartToFinish()-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] onMoveToNextExamPart() putStudentExamPartToFinish()-catch END`, error);
      });//↑終了通知

    },//onMoveToNextExamPart()
    onFinishAll() {
      this.stopCountDownTimer();//カウントダウン停止
      this.resetError();
      this.resetStatus();

      //サーバーへ現在の検査（＝最終の検査）の終了を通知
      this.putStudentExamPartToFinish()
      .then((response) => {
        console.log(`[${LogHeader}] onFinishAll() putStudentExamPartToFinish()-then START`, response);
        this.resetCountDownTimer();

        //結果画面へ遷移
        this.$router.push({ name: 'result' });

        console.log(`[${LogHeader}] onFinishAll() putStudentExamPartToFinish()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] onFinishAll() putStudentExamPartToFinish()-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] onFinishAll() putStudentExamPartToFinish()-catch END`, error);
      });//↑終了通知

    },//onFinishAll()
  },//method
  watch: {
    remainingSec: {
      handler(newValue, oldValue) {
        //console.log(`[${LogHeader}] watch:remainingSec(${newValue},${oldValue}) CALLED`);
        if (newValue != null) {
          if (newValue <= 0) {
            console.log(`[${LogHeader}] watch:remainingSec(${newValue},${oldValue}) CALLED → TIME IS UP !!`);
            //this.processPutStudentExamPartWithTmpData(false/*スキップ不可*/);//モーダル表示前に確実に現状を保存しておく //XXX タイムアップをモーダル表示しないなら不要

            if (!this.isLastExamPart) {
              //XXX タイムアップをモーダル表示する？ ※b1の挙動未確認

              //現在の検査を終了
              this.onMoveToNextExamPart();
            } else {
              //XXX タイムアップをモーダル表示する？ ※b1ではいきなり結果画面に遷移した

              //現在の検査（＝最終検査）を終了（＆結果画面へ遷移）
              this.onFinishAll();
            }
          } else if (this.currentExaminationPart.client_report_interval_sec > 0 &&
                      newValue % this.currentExaminationPart.client_report_interval_sec == 0) {
            console.log(`[${LogHeader}] watch:remainingSec(${newValue},${oldValue}) CALLED → reporting`);

            //定期的に（client_report_interval_sec間隔）サーバーへ現在の答案一式やページ番号を通知
            this.processPutStudentExamPartWithTmpData(true/*ページと回答に変更がなければスキップ*/);
          }
        }//if(newValue!=null)
      }
    },
  },//remainingSec
}
</script>

<style lang="sass" scoped>
.outer-card
  width: 60rem
#examination_parts_wrapper
  width: 100%
</style>
