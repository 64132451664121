<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 _col-md-10 _offset-md-1 col-lg-auto mx-lg-auto outer-card">
        <div class="card border-primary">

          <div class="card-header text-white bg-primary">
            <div class="row">
              <div class="col-12 col-lg-4 _pt-sm-1"><!-- ログアウトボタン等を標準サイズにする場合、この pt-sm-1 は↓のh5を上下中央にする為に必要 -->
                <span class="my_h5">社会人基礎力テスト {{ examination? examination.exam_name: '' }}<span class="d-none d-sm-inline px-1"></span><br class="d-sm-none" />{{ dispType.title }}</span>
              </div>
              <div v-if="student" class="col-12 col-lg-8 mt-2 mt-lg-0 text-right">
                <span class="align-middle mx-1">
                  {{ student.school.school_name }}
                  {{ student.school_faculty? `${student.school_faculty.faculty_name} `: '' }}
                  {{ student.school_department? `${student.school_department.department_name} `: '' }}
                </span>
                <router-link v-if="student&&!student.finished_at" class="btn btn-sm btn-primary _btn-outline-light mx-1" :to="{ name: 'profile' }">{{ student.full_name? `${student.full_name} さん`: student.login_id }}</router-link>
                <span v-else-if="student&&student.finished_at" class="align-middle mx1">{{ student.full_name? `${student.full_name} さん`: student.login_id }}</span>
                <button class="btn btn-sm btn-primary _btn-outline-light mx-1" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
              </div>
            </div>
          </div>

          <div class="card-body" :class="{ result_wrapper : dispType==DispTypes.result }">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">

                <a v-if="dispType==DispTypes.result" class="btn btn-sm btn-primary mx-2 mb-1" :href="`/export/personal_report?token=${token}`" target="_blank">PDFでダウンロード</a>
                <button v-else class="btn btn-sm btn-success mx-2 mb-1" :disabled="!isAvailable" @click.prevent="onShowResult">個人結果表に戻る</button>

                <button v-if="hasExamPart3&&dispType!=DispTypes.desc31" class="btn btn-sm btn-success mx-2 mb-1" :disabled="!isAvailable" @click.prevent="onShowDesc31">検査３－１の解説を見る</button>
                <button v-if="hasExamPart3&&dispType!=DispTypes.desc32" class="btn btn-sm btn-success mx-2 mb-1" :disabled="!isAvailable" @click.prevent="onShowDesc32">検査３－２の解説を見る</button>

                <router-link tag="button" :to="{ name: 'home' }" class="btn btn-sm btn-primary mx-2 mb-1">トップページへ戻る</router-link>

              </div>
            </div>

            <template v-if="student">

              <div v-if="dispType==DispTypes.result" class="row mt-3">
                <div class="col-12">
                  <PersonalReport :schoolExamStudent="student" />
                </div>
              </div><!-- row DispTypes.result -->

              <template v-else-if="[ DispTypes.desc31, DispTypes.desc32 ].includes(dispType)">

                <div class="row">
                  <div class="col-12 col-sm-10 offset-sm-1 text-right">
                    <button v-for="p in currentExaminationPart.page_count" :key="`page_${p}`" class="btn btn-link my_16px" :class="{ 'font-weight-bold': p==currentPage }" @click.prevent="onMovePage(p)">
                      <template v-if="p==1">最初</template>
                      <template v-else-if="p==currentExaminationPart.page_count">最後</template>
                      <template v-else>{{ p }}～</template>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-10 offset-sm-1 mt-2" id="examination_parts_wrapper">
                    <div v-if="false">検査３のメインコンポーネント（{{ examPartMainComponent }}）</div>
                    <component :is="examPartMainComponent" :examinationPart="currentExaminationPart" :currentPage="currentPage" :canEdit="false" :showDescription="true" v-model="currentExaminationPart.all_page_params_set"></component>
                  </div>
                </div><!-- row DispTypes.desc31 -->

                <div class="row">
                  <div class="col-12 col-sm-10 offset-sm-1 text-right">
                    <button v-if="currentPage>1" class="btn btn-primary mx-1 my-1" @click.prevent="onMovePage(currentPage-1)">前の画面へ</button>
                    <button v-if="currentPage<currentExaminationPart.page_count" class="btn btn-primary mx-1 my-1" @click.prevent="onMovePage(currentPage+1)">次の画面へ</button>
                  </div>
                </div>

              </template><!-- desc31,desc32 -->

            </template>

            <div class="row mt-4">
              <div class="col-12">

                <a v-if="dispType==DispTypes.result" class="btn btn-sm btn-primary mx-2 mt-1" :href="`/export/personal_report?token=${token}`" target="_blank">PDFでダウンロード</a>
                <button v-else class="btn btn-sm btn-success mx-2 mt-1" :disabled="!isAvailable" @click.prevent="onShowResult">個人結果表に戻る</button>

                <button v-if="hasExamPart3&&dispType!=DispTypes.desc31" class="btn btn-sm btn-success mx-2 mt-1" :disabled="!isAvailable" @click.prevent="onShowDesc31">検査３－１の解説を見る</button>
                <button v-if="hasExamPart3&&dispType!=DispTypes.desc32" class="btn btn-sm btn-success mx-2 mt-1" :disabled="!isAvailable" @click.prevent="onShowDesc32">検査３－２の解説を見る</button>

                <router-link tag="button" :to="{ name: 'home' }" class="btn btn-sm btn-primary mx-2 mt-1">トップページへ戻る</router-link>

              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
import dp2_t3_1 from '../../common/components/examination_parts/dp2_t3_1';
import dp2_t3_2 from '../../common/components/examination_parts/dp2_t3_2';
import dp3_t3_1 from '../../common/components/examination_parts/dp3_t3_1';
import dp3_t3_2 from '../../common/components/examination_parts/dp3_t3_2';
import PersonalReport from '../../common/components/PersonalReportComponent';
const LogHeader = 'Result.vue';
const DispTypes = {
  result: { title: '個人結果表', },
  desc31: { title: '検査3-1 解説', },
  desc32: { title: '検査3-2 解説', },
};

export default {
  PageTitle: '個人結果表',
  components: {
    dp2_t3_1, dp2_t3_2,
    dp3_t3_1, dp3_t3_2,
    PersonalReport,
  },
  mixins: [commonMixin],
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      token: window.token,//see https://jwt-auth.readthedocs.io/en/develop/quick-start/ > Authenticated requests > Query string parameter
      resourceApi,
      schoolExamination: null,
      examination: null,
      examinationParts: null,
      student: null,
      DispTypes,
      dispType: DispTypes.result,
      currentExamPartIdx: null,
      currentPage: null,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true, 'データを取得しています...');
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.examinationParts = response.data.examination_parts;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        //全ての検査が終了していない場合はhomeにリダイレクト
        if (!this.student.finished_at) {
          console.log('redirecting result to home');
          this.$router.push({ name: 'home' });
        }

        //検査３－１、３－２の正解・解説をall_page_params_setに埋め込み
        if (this.hasExamPart3) {
          //210806MOD desc_answer導入に伴い、第２引数を追加した ※examinationPartsとmy_real_examination_partsの並びが同じであることを前提としている
          this.reflectExamPartEvaluationSet(this.examinationParts[2], this.student.my_real_examination_parts[2]);
          this.reflectExamPartEvaluationSet(this.examinationParts[3], this.student.my_real_examination_parts[3]);
        }

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    hasExamPart3() { return this.examinationParts && this.examinationParts.length == 4; },//簡易的な判定（検査３（３－１、３－２）があると必ず４）
    currentExaminationPart() { return this.hasExamPart3&&[2,3].includes(this.currentExamPartIdx)? this.examinationParts[this.currentExamPartIdx]: null; },
    examPartMainComponent() { return this.currentExaminationPart? this.currentExaminationPart.main_component_name: null; },
    isLastPage() { return this.currentExaminationPart? (this.currentPage == this.currentExaminationPart.page_count ): false; },
  },
  methods: {
    reflectExamPartEvaluationSet(examinationPart, myExaminationPart) {//210806MOD 引数にmyExaminationPartを追加した
      //正解＆解説一式（evaluation_setの各answerとdescription）をall_page_params_setに埋め込む ※admin/views/master/examination_parts/Edit.vueのafterCreatedGetThen()と似たロジック
      //210806MOD 解説時は問題部のチェックボックスには受験者の回答を表示する必要があるので（解説側の正解表示は従来通り）、解説側の正解を desc_answer として分離した
      console.log(`[${LogHeader}] reflectExamPartEvaluationSet() START`);
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        let pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            let questionParams = pageParams[questionKey];
            questionParams.answer = myExaminationPart.answer_set[questionKey] ?? [];//210806MOD 受験者の回答内容をセットする（従来は正解をセットしていた）
            questionParams.desc_answer = examinationPart.evaluation_set[questionKey].answer ?? [];//210806ADD
            questionParams.description = examinationPart.evaluation_set[questionKey].description ?? '';
            //console.log(`[${pageKey}][${questionKey}] answer=[${examinationPart.all_page_params_set[pageKey][questionKey].answer}] desc_answer=[${examinationPart.all_page_params_set[pageKey][questionKey].desc_answer}]`);//デバッグ用（本番コメントアウト）
            //console.log(`[${pageKey}][${questionKey}].descrpition=[${examinationPart.all_page_params_set[pageKey][questionKey].description}]`);//デバッグ用（本番コメントアウト）
          }
        }
      }
      console.log(`[${LogHeader}] reflectExamPartEvaluationSet() END`, examinationPart.all_page_params_set);
    },
    onMovePage(newPage) {
      this.currentPage = newPage;
      window.scrollTo(0, 0);
    },
    onShowResult() {
      console.log(`[${LogHeader}] onShowResult() CALLED`);
      this.dispType = DispTypes.result;
      this.currentExamPartIdx = null;
      this.currentPage = null;
      window.scrollTo(0, 0);
    },
    onShowDesc31() {
      console.log(`[${LogHeader}] onShowDesc31() CALLED`);
      this.dispType = DispTypes.desc31;
      this.currentExamPartIdx = 2;
      this.currentPage = 1;
      window.scrollTo(0, 0);
    },
    onShowDesc32() {
      console.log(`[${LogHeader}] onShowDesc32() CALLED`);
      this.dispType = DispTypes.desc32;
      this.currentExamPartIdx = 3;
      this.currentPage = 1;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"
.outer-card
  width: 80rem
.card-body.result_wrapper
  +mq(max_575)
    padding: 1.0rem 0.5rem
#examination_parts_wrapper
  width: 100%
</style>
