import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import Error_General from './views/errors/General.vue';
import Login from './views/Login';
import Forgot from './views/Forgot';
import Home from './views/Home';
import Profile from './views/Profile';
import Questionnaire from './views/Questionnaire';
import Exam from './views/Exam';
import Result from './views/Result';

Vue.use(Router);

export default new Router ({
  mode: 'history',
  base: '/',//process.env.BASE_URL,

  routes: [

    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter(to, from, next) {
        console.log(`login beforeEnter() CALLED`);
        if (store.getters['auth/isLoggedIn']) {
          console.log(`redirecting login to home`);
          next({//ログイン済みならhomeへ
            name: 'home',
          });
        } else {
          next();
        }
      },
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: Forgot,
      beforeEnter(to, from, next) {
        console.log(`forgot beforeEnter() CALLED`);
        if (store.getters['auth/isLoggedIn']) {
          console.log(`redirecting forgot to home`);
          next({//ログイン済みならhomeへ
            name: 'home',
          });
        } else {
          next();
        }
      },
    },

    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter(to, from, next) {
        console.log(`home beforeEnter() CALLED`);
        if (store.getters['auth/isLoggedIn']) {
          next();
        } else {
          console.log(`redirecting home to login`);
          next({//未ログインならログイン画面へ
            name: 'login',
          });
        }
      },
    },

    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        'requiresLogin': true,
      },
      beforeEnter(to, from, next) {
        console.log(`exam beforeEnter() CALLED`);
        console.log('from ↓', from);
        console.log('to ↓', to);
        if (from.name == null) {
          console.log(`redirecting exam to home`);
          next({//事故防止の為、直接アクセスならhomeへ
            name: 'home',
          });
        } else {
          next();
        }
      },
    },

    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: Questionnaire,
      meta: {
        'requiresLogin': true,
      },
      beforeEnter(to, from, next) {
        console.log(`exam beforeEnter() CALLED`);
        console.log('from ↓', from);
        console.log('to ↓', to);
        if (from.name == null) {
          console.log(`redirecting exam to home`);
          next({//事故防止の為、直接アクセスならhomeへ
            name: 'home',
          });
        } else {
          next();
        }
      },
    },

    {
      path: '/exam',
      name: 'exam',
      component: Exam,
      meta: {
        'requiresLogin': true,
      },
      beforeEnter(to, from, next) {
        console.log(`exam beforeEnter() CALLED`);
        console.log('from ↓', from);
        console.log('to ↓', to);
        if (from.name == null) {
          console.log(`redirecting exam to home`);
          next({//事故防止の為、直接アクセスならhomeへ
            name: 'home',
          });
        } else {
          next();
        }
      },
    },

    {
      path: '/result',
      name: 'result',
      component: Result,
      meta: {
        'requiresLogin': true,
      },
      beforeEnter(to, from, next) {
        console.log(`exam beforeEnter() CALLED`);
        console.log('from ↓', from);
        console.log('to ↓', to);
        if (from.name == null) {
          console.log(`redirecting exam to home`);
          next({//事故防止の為、直接アクセスならhomeへ
            name: 'home',
          });
        } else {
          next();
        }
      },
    },

  ],
});
