<template>
  <div>

    <div class="row mt-5">
      <div class="col-12 col-md-10 offset-md-1 col-lg-auto mx-lg-auto outer-card">
        <div class="card border-primary">

          <div class="card-header text-white bg-primary text-center">
            <span class="my_h5">社会人基礎力テスト DP<span class="d-none d-sm-inline px-1"></span><br class="d-sm-none" />ログイン画面</span>
          </div>

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <div class="row justify-content-center">

              <div class="col-12 mb-4 col-sm-10 col-lg-6 mb-lg-0">
                <div class="card border-secondary">
                  <div class="card-body px-3 py-2">
                    <div class="row">
                      <div class="col card-title font-weight-bold">お知らせ</div>
                    </div>
                    <div class="row my-2" v-for="notice in notices" :key="`notice_${notice.id}`">
                      <div class="col-3 col-sm-0 d-sm-none text-center">{{ notice.notice_date_nj }}</div>
                      <div class="col-0 col-sm-3 d-none d-sm-inline">{{ notice.notice_date_md_jp }}</div>
                      <div class="col-9 col-sm-9">
                        <a href="#" @click.prevent class="_btn _btn-link" data-toggle="modal" :data-target="`#notice_${notice.id}_content`">
                          {{ notice.title }}
                        </a>
                      </div>
                      <div class="modal fade" :id="`notice_${notice.id}_content`" tabindex="-1" role="dialog">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <span class="modal-title h5">{{ notice.notice_date_nj }}&nbsp;&nbsp;{{ notice.title }}</span>
                              <button class="close" data-dismiss="modal" aria-label="Close"><span>&times;</span></button>
                            </div>
                            <div class="modal-body">{{ notice.content }}</div>
                            <div class="modal-footer"><button class="btn btn-secondary" data-dismiss="modal">閉じる</button></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div><!-- card-body -->
                </div><!-- card -->
              </div><!-- col -->

              <div class="col-12 col-sm-10 col-lg-5">
                <form class="card border-primary bg-light">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 my_16px text-center">ID・パスワードを入力して下さい。</div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 offset-0 col-md-8 offset-md-2 col-lg-12 offset-lg-0">
                        <input id="inputLoginId" type="text" class="form-control" :class="{ 'is-invalid':hasError('login_id') }" v-model="form.login_id" placeholder="ログインID">
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 offset-0 col-md-8 offset-md-2 col-lg-12 offset-lg-0">
                        <input id="inputPassword" type="password" class="form-control" :class="{ 'is-invalid':hasError('password') }" v-model="form.password" placeholder="パスワード">
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 text-center">
                        <button class="btn btn-primary mx-2" :disabled="!isAvailable" @click.prevent="onClickLoginButton">ログイン</button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 offset-0 col-md-8 offset-md-2 col-lg-12 offset-lg-0">
                        <router-link class="btn btn-link" :to="{ name: 'forgot' }">パスワードを忘れた方</router-link>
                      </div>
                    </div>
                  </div><!-- card-body -->
                </form><!-- card -->
              </div><!-- col -->

            </div><!-- row -->

            <div class="row mt-3">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
const LogHeader = 'Login.vue';

export default {
  PageTitle: 'ログイン',
  mixins: [commonMixin],
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      form: {
        login_id: '',
        password: '',
      },
      notices: null,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true/*, 'データを取得しています...'*/);
    const url = `notices?page=all`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.notices = response.data.list;
        this.setLoadingStatus(false);
        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
  },
  methods: {
    async onClickLoginButton() {
      console.clear();
      console.log(`[${LogHeader}] onClickLoginButton() START`);
      this.resetError();
      this.resetStatus();
      await this.$store.dispatch('auth/login', this.form);
      if (this.isHttpStatusOK) {
        this.$router.push({ name: 'home' });
      }
      console.log(`[${LogHeader}] onClickLoginButton() END`);
    }
  },
};
</script>

<style lang="sass" scoped>
.outer-card
  width: 60rem
</style>
