<template>
  <div>

    <div class="row mt-5">
      <div class="col-12 col-md-10 offset-md-1 col-lg-auto mx-lg-auto outer-card">
        <div class="card border-primary">

          <div class="card-header text-white bg-primary">
            <span class="my_h5">社会人基礎力テスト DP<span class="d-none d-sm-inline px-1"></span><br class="d-sm-none" />パスワード再送</span>
          </div>

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 my_16px">
                <div>登録した学籍番号とメールアドレスを入力して下さい。</div>
                <div>パスワードをメールにてお知らせします。</div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-12">
                <input id="inputStudentNo" type="text" class="form-control" :class="{ 'is-invalid':hasError('student_no') }" v-model="form.student_no" placeholder="学籍番号">
              </div>
            </div>
            <div class="row my-3">
              <div class="col-12">
                <input id="inputMailAddress" type="email" class="form-control" :class="{ 'is-invalid':hasError('mail_address') }" v-model="form.mail_address" placeholder="メールアドレス">
              </div>
            </div>
            <div class="row my-3">
              <div class="col-12 text-center">
                <button class="btn btn-primary mx-2 my-2" :disabled="!isAvailable" @click.prevent="onClickSendButton">ログイン情報を送信する</button>
                <button class="btn btn-secondary mx-2 my-2" :disabled="!isAvailable" @click.prevent="onCancel">戻る</button>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import commonMixin from '../mixins/common';
const LogHeader = 'Forgot.vue';

export default {
  PageTitle: 'パスワード再送',
  mixins: [commonMixin],
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      form: {
        student_no: '',
        mail_address: '',
      },
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
  },
  methods: {
    async onClickSendButton() {
      console.clear();
      console.log(`[${LogHeader}] onClickSendButton() START`);
      this.resetError();
      this.resetStatus();
      this.setLoadingStatus(true/*, 'メールを送信しています...'*/);
      await this.$store.dispatch('auth/mail', this.form);
      this.setLoadingStatus(false);
      if (this.isHttpStatusOK) {
        this.setStatusMessage(`ログイン情報を ${this.form.mail_address} に送信しました。`);
        this.setTimeoutToGoToLogin();
      }
      console.log(`[${LogHeader}] onClickSendButton() END`);
    }
  },
};
</script>

<style lang="sass" scoped>
.outer-card
  width: 50rem
</style>
