<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 _col-md-10 _offset-md-1 col-lg-auto mx-lg-auto outer-card">
        <div class="card border-primary">

          <div class="card-header text-white bg-primary">
            <div class="row">
              <div class="col-12 col-lg-4 _pt-sm-1"><!-- ログアウトボタン等を標準サイズにする場合、この pt-sm-1 は↓のh5を上下中央にする為に必要 -->
                <span class="my_h5">社会人基礎力テスト {{ examination? examination.exam_name: '' }}</span>
              </div>
              <div v-if="student" class="col-12 col-lg-8 mt-2 mt-lg-0 text-right">
                <span class="align-middle mx-1">
                  {{ student.school.school_name }}
                  {{ student.school_faculty? `${student.school_faculty.faculty_name} `: '' }}
                  {{ student.school_department? `${student.school_department.department_name} `: '' }}
                </span>
                <router-link v-if="student&&!student.finished_at" class="btn btn-sm btn-primary _btn-outline-light mx-1" :to="{ name: 'profile' }">{{ student.full_name? `${student.full_name} さん`: student.login_id }}</router-link>
                <span v-else-if="student&&student.finished_at" class="align-middle mx1">{{ student.full_name? `${student.full_name} さん`: student.login_id }}</span>
                <button class="btn btn-sm btn-primary _btn-outline-light mx-1" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
              </div>
            </div>
          </div><!-- card-header -->

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <div v-if="schoolExamination" class="row pl-sm-5">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 card-title my_h5">
                    社会人基礎力テスト
                  </div>
                </div>
                <div class="row mt-2 pl-sm-5">
                  <ul class="col-12 list-unstyled my_h5">
                    <li v-for="examinationPart in examinationParts" :key="`exam_part_${examinationPart.id}`">
                      {{ examinationPart.exam_part_name }} {{ examinationPart.exam_part_name2 }}（制限時間 {{ examinationPart.time_limit_sec / 60 }} 分）
                    </li>
                  </ul>
                </div>
                <div v-if="!student.finished_at" class="row pl-sm-5">
                  <div class="col-12 my_h5">
                    <div class="my_h5">試験開始後は、最後まで受験して下さい。</div>
                    <div class="small">※インターネット接続の不具合などで、試験の途中で止まってしまったときは、再度、ログインして受験を再開して下さい。</div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12 text-center">
                    <template v-if="!schoolExamination.is_disabled">
                      <button v-if="!student.started_at" class="btn btn-lg btn-primary px-4 my_h5" data-toggle="modal" data-target="#modalStart_1">試験を開始する（{{ totalTimeLimitMinutes }}分）</button>
                      <router-link v-else-if="!student.finished_at" tag="button" :to="{ name: 'exam' }" class="btn btn-lg btn-danger px-4 my_h5">試験を再開する</router-link>
                      <router-link v-else tag="button" :to="{ name: 'result' }" class="btn btn-lg btn-warning px-4 my_h5">試験完了（結果・解説を見る）</router-link>
                    </template>
                    <template v-else><!-- 無効化日時を過ぎている場合 -->
                      <button v-if="!student.started_at" class="btn btn-lg btn-primary px-4 my_h5" disabled>試験を開始する（{{ totalTimeLimitMinutes }}分）</button><!-- （未開始の場合）開始させない -->
                      <router-link v-else tag="button" :to="{ name: 'result' }" class="btn btn-lg btn-warning px-4 my_h5" :disabled="!student.finished_at">試験完了（結果・解説を見る）</router-link><!-- 他は全て結果画面へ誘導 -->
                    </template>
                  </div><!-- col -->
                </div>
              </div><!-- col -->
            </div><!-- row -->

            <div class="row mt-3">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

    <div v-if="schoolExamination" class="modal fade" id="modalStart_1" ref="modalStart_1" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg _modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="h5">確認画面</span>
            <button class="close" data-dismiss="modal" aria-label="Close"><span>&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 my_16px">今回の試験は{{ kensaCountStr }}の科目で構成されています。</div>
              </div>
              <div class="row mt-2">
                <ul class="col-12 list-unstyled">
                  <li class="my_16px" v-for="examinationPart in examinationParts" :key="`exam_part_${examinationPart.id}`">
                    {{ examinationPart.exam_part_name }} {{ examinationPart.exam_part_name2 }}（制限時間 {{ examinationPart.time_limit_sec / 60 }} 分）
                  </li>
                </ul>
              </div>
              <div class="row">
                <div class="col-12 my_16px">
                  <div>１つの検査が終了すると自動的に次の検査が開始されます。</div>
                  <div>試験を開始すると中断することはできません。必ず最後まで受験して下さい。</div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 text-right">
                  <button class="btn btn-secondary mx-2" data-dismiss="modal">閉じる</button>
                  <button type="button" class="btn btn-primary mx-2" data-toggle="modal" data-target="#modalStart_2" @click="hideModal('modalStart_1')">次へ</button>
                </div>
              </div>
            </div><!-- container-fluid -->
          </div><!-- modal-body -->
        </div>
      </div>
    </div><!-- modal -->

    <div class="modal fade" id="modalStart_2" tabindex="-1" role="dialog">
      <div class="modal-dialog _modal-lg _modal-dialog-centered" role="document">
        <div class="modal-content bg-light">
          <div class="modal-body">
            <div>これより試験を開始します。</div>
            <div class="mt-1 my_16px text-danger">それでは「受験する」ボタンを押して受験を開始して下さい。</div>
            <div class="mt-1 small">※受験を開始しない場合は「閉じる」ボタンを押して下さい。</div>
            <div class="mt-2 text-right">
              <button class="btn btn-secondary mx-2" data-dismiss="modal">閉じる</button>
              <router-link tag="button" :to="{ name: 'exam' }" class="btn btn-primary mx-2" data-dismiss="modal">受験する</router-link>
            </div>
          </div>
        </div>
      </div>
    </div><!-- modal -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
const LogHeader = 'Home.vue';

export default {
  //PageTitle: 'Webテスト',
  mixins: [commonMixin],
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      schoolExamination: null,
      examination: null,
      examinationParts: null,
      student: null,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true, 'データを取得しています...');
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.examinationParts = response.data.examination_parts;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        if (!this.student.registered_at) {//プロフィール未登録ならプロフィール画面へリダイレクト
          console.log('redirecting home to profile');
          this.$router.push({ name: 'profile'});
        } else if (this.schoolExamination.has_questionnaire &&//アンケートあり？
                    !this.student.questionnaire_answer_set.answered_at) {//アンケート未回答ならアンケート画面へリダイレクト
          console.log('redirecting home to questionnaire');
          this.$router.push({ name: 'questionnaire'});
        }

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    totalTimeLimitMinutes() {
      let timeLmitSecSum = 0;
      if (this.examinationParts) {
        this.examinationParts.forEach((value, index, array) => { timeLmitSecSum += value.time_limit_sec; });
      }
      return timeLmitSecSum / 60;
    },
    kensaCountStr() { return this.examinationParts.length>2? `３つ`: `２つ`; },//２つか３つしかない前提
  },
  methods: {
  },
}
</script>

<style lang="sass" scoped>
.outer-card
  width: 80rem
</style>
