<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 col-md-10 offset-md-1 col-lg-auto mx-lg-auto outer-card">
        <div class="card border-primary">

          <div class="card-header text-white bg-primary">
            <div class="row">
              <div class="col-12 col-lg-6 _pt-sm-1"><!-- ログアウトボタン等を標準サイズにする場合、この pt-sm-1 は↓のh5を上下中央にする為に必要 -->
                <span class="my_h5">社会人基礎力テスト {{ examination? examination.exam_name: '' }}<span class="d-none d-sm-inline px-1"></span><br class="d-sm-none" />アンケート</span>
              </div>
              <div v-if="student" class="col-12 col-lg-6 text-right">
                <span class="align-middle mx-3">{{ student.school.school_name }}</span>
                <button class="btn btn-sm btn-primary _btn-outline-light mx-1" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
              </div>
            </div>
          </div>

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <div class="row" v-if="student">
              <form class="col-12 col-sm-10 offset-sm-1">

                <div class="form-group">
                  <span class="my_h5">テストの前に以下のアンケートがあります。</span>
                </div>

                <div v-for="(questionnaire, idx) in questionnaires" :key="`questionanire_${questionnaire.id}`" class="form-group">
                  <template v-if="questionnaire.question">
                    <div class="my_h5">【設問{{ questionnaire.question_no }}】</div>
                    <div class="my_h6 mx-2 mt-1">{{ questionnaire.question }}</div>
                    <div v-for="n in questionnaire.choices_count" :key="`choice_${n}`" class="form-check mx-3 mt-2">
                      <input class="form-check-input" type="radio" :id="`inputQ${idx+1}A${n}`" :class="{ 'is-invalid': hasError(`questionnaire_answer_set.${questionnaire.answer_key}`) }" :value="n" v-model="questionnaire_answer_set[questionnaire.answer_key]">
                      <label class="form-check-label" :for="`inputQ${idx+1}A${n}`">{{ questionnaire[`choice${('0'+n).slice(-2)}`] }}</label>
                    </div>
                  </template>
                </div>

                <div class="form-group text-center">
                  <button class="btn btn-primary mx-2 my-2" :disabled="!isAvailable" @click.prevent="onSave">回答を送信する</button>
                </div>

              </form>
            </div><!-- row -->

            <div class="row mt-3">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
const LogHeader = 'Questionnaire.vue';

export default {
  PageTitle: 'アンケート',
  mixins: [commonMixin],
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      schoolExamination: null,
      examination: null,
      student: null,
      questionnaire_answer_set: {
        answer01: null,
        answer02: null,
        answer03: null,
        answer04: null,
        answer05: null,
        answer06: null,
        answer07: null,
        answer08: null,
        answer09: null,
        answer10: null,
      },
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true, 'データを取得しています...');
    const url = `school_examination/questionnaires`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.questionnaires = response.data.questionnaires;
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.student = response.data.student;
        this.questionnaire_answer_set = this.student.questionnaire_answer_set;
        this.setLoadingStatus(false);
        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
  },
  methods: {
    onSave(stayAfterSaved) {
      console.log(`[${LogHeader}] onSave() START`);
      scrollTo(0, 0);
      this.resetError();
      this.resetStatus();
      this.setSavingStatus(true, '回答内容を保存しています...');
      let params = {
        id: this.student.id,
        //questionnaire_answer_set: this.questionnaire_answer_set,
        questionnaire_answer_set: {
          answer01: this.questionnaire_answer_set.answer01,
          answer02: this.questionnaire_answer_set.answer02,
          answer03: this.questionnaire_answer_set.answer03,
          answer04: this.questionnaire_answer_set.answer04,
          answer05: this.questionnaire_answer_set.answer05,
          answer06: this.questionnaire_answer_set.answer06,
          answer07: this.questionnaire_answer_set.answer07,
          answer08: this.questionnaire_answer_set.answer08,
          answer09: this.questionnaire_answer_set.answer09,
          answer10: this.questionnaire_answer_set.answer10,
        }
      };
      const url = `school_exam_student/questionnaire`;
        this.resourceApi
        .putSpecial(url, params)
        .then((response) => {
          console.log(`[${LogHeader}] onSave() putSpecial(${url})-then START`, response);
          this.student = response.data.school_exam_student;
          this.setStatusMessage(`回答内容を保存しました。`);
          this.setTimeoutToGoBack();
          console.log(`[${LogHeader}] onSave() putSpecial(${url})-then END`);
        }).catch((error) => {
          console.error(`[${LogHeader}] onSave() putSpecial(${url})-catch START`, error.response);
          this.setResult(error.response);
          this.setErrorMessage(`回答内容を保存できませんでした。`);
          this.setSavingStatus(false);
          console.error(`[${LogHeader}] onSave() putSpecial(${url})-catch END`);
        });
      console.log(`[${LogHeader}] onSave() END`);
    },//onSave()
  },
};
</script>

<style lang="sass" scoped>
.outer-card
  width: 60rem
</style>
